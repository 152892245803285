import Vue from 'vue';
import Router from 'vue-router';

import Start from './views/Start.vue';
import Offer from './views/Offer.vue';
import LiabilitiesDetails from './views/LiabilitiesDetails';
import Payment from './views/Payment.vue';
import PersonalDetails from './views/PersonalDetails.vue';
import Result from './views/Result';
import ResultLiabilities from './views/ResultLiabilities';
import NegativeResult from './views/NegativeResult';
import ErrorPage from './views/ErrorPage';
import ChooseProduct from './views/ChooseProduct';
import BackgroundInformation from './views/BackgroundInformation';
import FinancialDetails from './views/FinancialDetails';
import CoApplicantDetails from './views/CoApplicantDetails';
import UploadIncomeProof from './views/UploadIncomeProof';
import BeginSigning from './views/signing/BeginSigning';
import NavigateToSigning from './views/signing/NavigateToSigning';
import HandleCallback from './views/signing/HandleCallback';
import CoBorrowerRefinancingChoice from './views/CoBorrowerRefinancingChoice';
import CorpHpNavigateToSigning from '@/views/signing/CorpHpNavigateToSigning.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    // Camunda task routes match Camunda task names
    // Each task must have an unique URL

    // Public application
    {
      path: '/',
      name: 'newApplication',
      component: PersonalDetails,
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/submitAdditionalPersonalData',
      name: 'submitAdditionalPersonalData',
      component: BackgroundInformation,
    },
    {
      path: '/submitFinancial',
      name: 'submitIncomesAndObligations',
      component: FinancialDetails,
    },
    {
      path: '/submitFinancialCoApplicant',
      name: 'submitIncomesAndObligationsCoApplicant',
      component: FinancialDetails,
    },
    {
      path: '/submitAdditionalPersonalDataCoApplicant',
      name: 'submitAdditionalPersonalDataCoApplicant',
      component: CoApplicantDetails,
    },
    // Scenario B
    {
      path: '/product',
      name: 'chooseProduct',
      component: ChooseProduct,
    },
    {
      path: '/personalDetails',
      name: 'personalDetails',
      component: PersonalDetails,
    },
    {
      path: '/backgroundInformation',
      name: 'backgroundInformation',
      component: BackgroundInformation,
    },
    {
      path: '/financial',
      name: 'financialDetails',
      component: FinancialDetails,
    },

    // Common scenario
    {
      path: '/offer',
      name: 'acceptOffer',
      component: Offer,
    },
    {
      path: '/enterLiabilitiesDetails',
      name: 'enterLiabilitiesDetails',
      component: LiabilitiesDetails,
    },
    {
      // for testing
      path: '/enterLiabilitiesDetailsCoApplicant',
      name: 'enterLiabilitiesDetailsCoApplicant',
      component: LiabilitiesDetails,
    },
    {
      path: '/payment',
      name: 'submitAdditionalData',
      component: Payment,
    },
    {
      path: '/uploadIncomeProof',
      name: 'uploadIncomeProof',
      component: UploadIncomeProof,
    },
    {
      path: '/uploadIncomeProofSentByEmail',
      name: 'uploadIncomeProofSentByEmail',
      component: UploadIncomeProof,
    },
    {
      path: '/uploadAdditionalDocs',
      name: 'uploadAdditionalDocs',
      component: UploadIncomeProof,
    },
    {
      path: '/result',
      name: 'viewNegativeThankYou',
      component: NegativeResult,
      props: { definitionKey: 'viewNegativeThankYou' },
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/result2',
      name: 'viewUndecidedThankYou',
      component: Result,
      props: { definitionKey: 'viewUndecidedThankYou' },
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/result4',
      name: 'viewIncomeProofUploadedThankYou',
      component: Result,
      props: { definitionKey: 'viewIncomeProofUploadedThankYou' },
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/result5',
      name: 'viewLiabilitiesEnteredThankYou',
      component: ResultLiabilities,
      props: { definitionKey: 'viewLiabilitiesEnteredThankYou' },
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/involveCoBorrowerOrUseRefinancing',
      name: 'involveCoBorrowerOrUseRefinancing',
      component: CoBorrowerRefinancingChoice,
    },

    // Signicat signing
    {
      path: '/beginSigning',
      name: 'beginSigning',
      component: BeginSigning,
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/applicantSigning',
      name: 'applicantSigning',
      component: NavigateToSigning,
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/coApplicantSigning',
      name: 'coApplicantSigning',
      component: NavigateToSigning,
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/signing/signicat/callback',
      name: 'callbackSigning',
      component: HandleCallback,
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/relatedPersonSigning',
      name: 'relatedPersonSigning',
      component: CorpHpNavigateToSigning,
      meta: { disableSessionExpiration: true },
    },

    // Frontend supporting routes
    {
      path: '/start',
      name: 'start',
      component: Start,
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: ErrorPage,
      props: { errorType: 'maintenance' },
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/error',
      name: 'error500',
      component: ErrorPage,
      props: { errorType: 'error500' },
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/session-expired',
      name: 'sessionExpired',
      component: ErrorPage,
      props: { errorType: 'session-expired' },
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: ErrorPage,
      props: { errorType: 'unauthorized' },
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/no-task',
      name: 'noTask',
      component: ErrorPage,
      props: { errorType: 'no-account' },
    },
    {
      path: '/no-process',
      name: 'noProcess',
      component: ErrorPage,
      props: { errorType: 'no-account' },
      meta: { disableSessionExpiration: true },
    },
    {
      path: '*',
      name: 'error404',
      component: ErrorPage,
      props: { errorType: 'error404' },
      meta: { disableSessionExpiration: true },
    },
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
